import React from "react";

import logo from "../images/logo.png";

export default function Header() {
  return (
    <>
      <nav className="bg-gradient-to-r from-slate-200 to-[#0C394D] border-white border-b-[3px]">
        <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl px-4 md:px-6 py-2.5">
          <a href="/" className="flex items-center">
            <img
              src={logo}
              className="h-6 mr-3 mb-2 sm:h-16"
              alt="Algitel Logo"
            />
            <span className="self-center text-xl font-semibold text-gray-50">
              <span className="self-center text-xl font-semibold text-[#3B3933]">
                Algitel |{" "}
              </span>
              Votre sécurité connectée
            </span>
          </a>
          <div className="flex items-center">
            <a
              href="tel:0479369226"
              className="mr-6 text-sm font-medium text-gray-500 dark:text-white hover:underline"
            >
              04 79 36 92 26
            </a>
          </div>
        </div>
      </nav>
      <nav className="bg-[#0C394D] opacity-[.90]">
        <div className="max-w-screen-xl px-4 py-3 mx-auto md:px-6">
          <div className="flex items-center">
            <ul className="flex flex-row mt-0 mr-6 space-x-8 text-sm font-medium">
              <li>
                <a
                  href="/"
                  className="text-gray-900 dark:text-white hover:underline"
                  aria-current="page"
                >
                  Accueil
                </a>
              </li>
              <li>
                <a
                  href="/services"
                  className="text-gray-900 dark:text-white hover:underline"
                >
                  Nos Services
                </a>
              </li>

              <li>
                <a
                  href="/contact"
                  className="text-gray-900 dark:text-white hover:underline"
                >
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
