import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import MainPage from "./pages/mainPage";
import ServicesPage from "./pages/servicesPage";
import ContactPage from "./pages/contactPage";
import NotFound from "./pages/notFoundPage";
import ConfidentialPoliticPage from "./pages/confidentialPoliticPage";
import LegalMentionPage from "./pages/legalMentionPage";

export default function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<MainPage />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route
            path="/politique-confidentialite"
            element={<ConfidentialPoliticPage />}
          />
          <Route path="/mentions-legales" element={<LegalMentionPage />} />
        </Routes>
      </Router>
    </div>
  );
}
