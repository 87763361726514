import React, { useState } from "react";

import { AiOutlineCheckCircle } from "react-icons/ai";
import { BsXCircle } from "react-icons/bs";

const CAPTCHA_LENGTH = 6; // Longueur du captcha

function generateCaptcha() {
  let captcha = "";
  for (let i = 0; i < CAPTCHA_LENGTH; i++) {
    const randomChar = Math.floor(Math.random() * 3);
    switch (randomChar) {
      case 0:
        captcha += String.fromCharCode(Math.floor(Math.random() * 26) + 65);
        break;
      case 1:
        captcha += String.fromCharCode(Math.floor(Math.random() * 26) + 97);
        break;
      case 2:
        captcha += Math.floor(Math.random() * 10);
        break;
      default:
        captcha += "";
    }
  }
  return captcha;
}

export default function Captcha({ onVerificationChange }) {
  const [captcha, setCaptcha] = useState(generateCaptcha());
  const [input, setInput] = useState("");
  const [isVerified, setIsVerified] = useState(false);

  function resetCaptcha() {
    setCaptcha(generateCaptcha());
    setInput("");
    setIsVerified(false);
  }

  function handleInputChange(event) {
    setInput(event.target.value);
    if (event.target.value === captcha) {
      setIsVerified(true);
      onVerificationChange(true);
    } else {
      setIsVerified(false);
      onVerificationChange(false);
    }
  }

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="flex flex-col items-center justify-center w-40 h-20 border border-gray-300 rounded-md shadow-lg">
        {isVerified ? (
          <AiOutlineCheckCircle className="w-8 h-8 text-green-500" />
        ) : (
          <div className="text-3xl font-bold text-gray-700">{captcha}</div>
        )}
      </div>
      <input
        type="text"
        value={input}
        onChange={handleInputChange}
        placeholder="Entrez le code"
        className={`w-40 h-12 mt-4 px-4 rounded-lg ${
          isVerified
            ? "bg-green-100 text-green-500 border-green-500"
            : input.length === CAPTCHA_LENGTH
            ? "bg-red-100 text-red-500 border-red-500"
            : "border-gray-300"
        }`}
      />
      {!isVerified && input.length === CAPTCHA_LENGTH && (
        <BsXCircle className="w-6 h-6 text-red-500 mt-2" />
      )}

      <input
        type="button"
        onClick={resetCaptcha}
        className="px-4 py-2 mt-2 text-white outline bg-transparent rounded-lg hover:bg-[#244C5E]"
        value="Réinitialiser"
      />
    </div>
  );
}
