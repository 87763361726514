import React from "react";

import Header from "../components/header_component";
import Footer from "../components/footer_component";

import alarme from "../images/alarme.png";
import telephone from "../images/telephone.png";
import camera from "../images/camera.png";
import maison from "../images/maison.png";
import son from "../images/son.png";
import interphone from "../images/interphone.png";
import fumigene from "../images/fumigene.png";
import acces from "../images/acces.png";

export default function ServicesPage() {
  const Card = ({ image, title, description, tags }) => {
    return (
      <div className="md:w-60 rounded overflow-hidden shadow-lg mx-5 my-5 ">
        <img className="w-full" src={image} alt={title} />
        <div className="px-6 py-4">
          <div className="font-bold text-xl mb-2">{title}</div>
          <p className="text-gray-700 text-base">{description}</p>
        </div>
        <div className="px-6 pt-4 pb-2">
          {tags.map((tag, idx) => (
            <span
              key={idx}
              className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2"
            >
              #{tag}
            </span>
          ))}
        </div>
      </div>
    );
  };

  const cards = [
    {
      id: 1,
      image: alarme,
      title: "Alarmes",
      description:
        "Enjoy the sun, sand, and sea with our beach vacation package.",
      tags: ["alarme", "sirene", "securite"],
    },
    {
      id: 2,
      image: telephone,
      title: "Standard téléphonique",
      description: "Escape to the mountains and breathe in the fresh air.",
      tags: ["telephone", "communication"],
    },
    {
      id: 3,
      image: camera,
      title: "Vidéoprotection",
      description:
        "Explore the city and immerse yourself in its culture and history.",
      tags: ["video", "surveillance", "camera"],
    },
    {
      id: 4,
      image: maison,
      title: "Automatismes pour Portes & Portails",
      description: "Escape to the mountains and breathe in the fresh air.",
      tags: ["automatisme", "portail"],
    },
    {
      id: 5,
      image: acces,
      title: "Contrôle d'accès",
      description: "Escape to the mountains and breathe in the fresh air.",
      tags: ["acces", "controle", "securite"],
    },
    {
      id: 6,
      image: interphone,
      title: "Interphone",
      description: "Escape to the mountains and breathe in the fresh air.",
      tags: ["interphone", "protection", "video"],
    },
    {
      id: 7,
      image: fumigene,
      title: "Fumigènes",
      description: "Escape to the mountains and breathe in the fresh air.",
      tags: ["fumigene", "dissuasion", "aveuglement"],
    },
    {
      id: 8,
      image: son,
      title: "Vente de matériels & Prestations évènementielles",
      description: "Escape to the mountains and breathe in the fresh air.",
      tags: ["sonorisation", "evenement", "materiel"],
    },
  ];

  return (
    <>
      <Header />
      <main>
        <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
          <h2 className="mb-6 text-4xl tracking-tight font-extrabold text-center text-[#244C5E]">
            Nos Services
          </h2>
          <div className="text-justify-center font-semibold">
            <p>
              En fonction de vos besoins et de vos attentes, nous proposons des
              tarifs et des temps d'installation adaptés à chaque service.{" "}
              <br /> Pour en savoir plus ou pour obtenir un devis personnalisé,
              n'hésitez pas à nous contacter. <br />
              Nous sommes présents dans toute la région Rhône-Alpes. <br />
              <span className="font-bold">
                <br />
                Faites le choix de la sécurité connectée avec ALGITEL !
              </span>
            </p>
          </div>
        </div>
        <div className="flex flex-wrap justify-center mb-5">
          {cards.map((card) => (
            <Card key={card.id} {...card} />
          ))}
        </div>
      </main>
      <Footer />
    </>
  );
}
