import React from "react";

import logo from "../images/logo.png";

export default function Footer() {
  return (
    <>
      <footer className="p-4 rounded-lg shadow md:px-6 md:py-8 bg-gradient-to-r from-slate-200 to-[#0C394D] border-white border-t-[3px] dark:bg-[#0C394D]">
        <div className="sm:flex sm:items-center sm:justify-between mr-3">
          <a href="/" className="flex items-center mb-4 sm:mb-0">
            <img src={logo} className="h-8 ml-3 mr-3" alt="Algitel Logo" />
          </a>
          <ul className="flex flex-wrap items-center mb-6 text-sm text-gray-500 dark:text-white sm:mb-0">
            <li>
              <a href="/services" className="mr-4 hover:underline md:mr-6 ">
                Nos Services
              </a>
            </li>
            <li>
              <a
                href="/mentions-legales"
                className="mr-4 hover:underline md:mr-6"
              >
                Mentions légales
              </a>
            </li>
            <li>
              <a
                href="/politique-confidentialite"
                className="mr-4 hover:underline md:mr-6 "
              >
                Politique de confidentialité
              </a>
            </li>
            <li>
              <a href="/contact" className="hover:underline">
                Contact
              </a>
            </li>
          </ul>
        </div>
        <hr className="my-6 border-white sm:mx-auto dark:border-white lg:my-8" />
        <span className="block text-sm text-gray-500 dark:text-white sm:text-center">
          ©{" "}
          <a href="/" className="hover:underline">
            Algitel
          </a>
          . Tous droits réservés.
        </span>
      </footer>
    </>
  );
}
