import { React, useRef, useState } from "react";
import emailjs from "@emailjs/browser";

import Header from "../components/header_component";
import Footer from "../components/footer_component";
import Captcha from "../components/captcha";

import { FaFacebookSquare, FaPhoneAlt } from "react-icons/fa";
import { IoLocationSharp } from "react-icons/io5";

export default function ContactPage() {
  const [captchaVerified, setCaptchaVerified] = useState(false);

  function handleVerificationChange(isCaptchaVerified) {
    setCaptchaVerified(isCaptchaVerified);
  }

  const handleClickScroll = () => {
    const element = document.getElementById("location");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_pd0aded",
        "template_ey89b0s",
        form.current,
        "M3zz6usDq5LKR1hB2"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("message envoyé");
          window.location.reload();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <>
      <Header />
      <section className="bg-[#AABAC1] pb-10">
        <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 dark:text-white">
            Contactez-nous !
          </h2>
          <p className="mb-8 lg:mb-16 font-light text-center text-[#374151] sm:text-xl">
            Vous pouvez nous contacter via le formulaire ci-dessous, par
            téléphone ou par mail.{" "}
          </p>
          <form ref={form} onSubmit={sendEmail} className="space-y-8">
            <div>
              <label className="block mb-2 text-md font-bold text-white">
                Email
              </label>
              <input
                type="email"
                name="email"
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                placeholder="monemail@exemple"
                required
              />
            </div>
            <div>
              <label className="block mb-2 text-md font-bold text-white">
                Sujet
              </label>
              <input
                type="text"
                name="subject"
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                placeholder="Comment pouvons-nous vous aider ?"
                required
              />
            </div>
            <div className="sm:col-span-2">
              <label className="block mb-2 text-md font-bold text-white">
                Votre message
              </label>
              <textarea
                name="message"
                rows="6"
                className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder="Laissez votre message ici ..."
              ></textarea>
            </div>
            <Captcha onVerificationChange={handleVerificationChange} />
            <button
              disabled={!captchaVerified}
              type="submit"
              className="bg-[#244C5E] hover:bg-[#374151] py-3 px-5 text-sm font-medium text-center text-white rounded-lg sm:w-fit focus:ring-4 focus:outline-none focus:ring-primary-300 dark:focus:ring-primary-800"
            >
              Envoyer
            </button>
          </form>
          <div className="grid mt-20 md:grid-cols-1 lg:grid-cols-3 gap-x-6 mb-12">
            <div className="mb-6 text-center mx-auto">
              <span onClick={handleClickScroll} className="cursor-pointer">
                <IoLocationSharp className="w-10 h-10 text-[#244C5E] mb-6 mx-auto" />
                <h6 className="font-medium">Yenne, 73170</h6>
              </span>
            </div>
            <div className="mb-6 text-center mx-auto">
              <a href="tel:0479369226">
                <FaPhoneAlt className="w-10 h-10 text-[#244C5E] mb-6 mx-auto" />
                <h6 className="font-medium">04 79 36 92 26</h6>
              </a>
            </div>
            <div className="text-center mx-auto">
              <a
                href="https://fr-fr.facebook.com/people/Algitel/100057438198488/"
                target="_blank"
                rel="noreferrer"
              >
                <FaFacebookSquare className="w-10 h-10 text-[#244C5E] mb-6 mx-auto" />
                <h6 className="font-medium">Facebook</h6>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="relative pt-10 pb-20 bg-[#CDD7E0]" id="location">
        <div
          className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
          style={{ height: "80px" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-[#CDD7E0] fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
        <h2 className="text-2xl font-semibold text-[#244C5E] pb-5">
          Retrouvez nous ici !
        </h2>
        <div className="mx-10">
          <iframe
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2786.2768596678793!2d5.759649051915101!3d45.70548237900211!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478b0db6d8e5d26b%3A0xdd3a974b6939201a!2sALGITEL!5e0!3m2!1sfr!2sfr!4v1678185163210!5m2!1sfr!2sfr"
            height="700"
            width="100%"
            allowFullScreen
          ></iframe>
        </div>
      </section>
      <Footer />
    </>
  );
}
