import React from "react";
import { useNavigate } from "react-router-dom";

export default function NotFound() {
  const navigate = useNavigate();

  function handleBackHome() {
    navigate("/");
  }

  return (
    <div>
      <h1>404 - Not Found</h1>
      <button onClick={handleBackHome}>Back to Home</button>
    </div>
  );
}
