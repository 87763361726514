import React from "react";

import Header from "../components/header_component";
import Footer from "../components/footer_component";

import security from "../images/mainSecurityPicture.png";
import camion from "../images/camion.png";

import { GrInfo } from "react-icons/gr";

export default function MainPage() {
  return (
    <>
      <Header />
      <section>
        <div className="relative">
          <img className="object-full" alt="" src={security} />
        </div>

        <section className="relative pt-10 pb-20 bg-[#CDD7E0]">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style={{ height: "90px" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-[#CDD7E0] fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
          <div className="container mx-auto px-4">
            <div className="items-center flex flex-wrap">
              <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
                <img
                  alt="camion algitel"
                  className="max-w-full rounded-lg shadow-lg mb-5"
                  src={camion}
                />
              </div>
              <div className="w-full md:w-5/12 ml-auto mr-auto px-4 mb-10">
                <div className="md:pr-12">
                  <div className="text-center inline-flex items-center justify-center mb-6 text-gray-600">
                    <GrInfo className="text-4xl" />
                  </div>
                  <p className="mt-4 text-md leading-relaxed text-gray-600">
                    Pour garantir votre sécurité et celle de vos biens, il est
                    recommandé d'installer un système de sécurité adapté à vos
                    besoins et à la configuration de votre propriété, tel qu'un
                    système d'alarme ou de vidéosurveillance. <br />
                    <br />
                    Assurez la sécurité de votre entrée et de vos passages en
                    optant pour des contrôles d'accès variés, tels que des
                    interphones, des portes et des portails automatisés.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      <Footer />
    </>
  );
}
